// @ts-nocheck
import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { ApolloProvider } from '@apollo/client';
import { ConfigProvider } from 'antd';
import fr from 'antd/lib/locale/fr_FR';
import AntForm, { AntFormRailsNested } from "@9troisquarts/ant-form";
import Layout from './components/Layout';
import routes from "./routes";
import TranslationProvider from "../../utils/TranslationProvider";
import graphqlClient from "../../utils/graphqlClient";
import flattenRoutes from "../../utils/flattenRoutes";
import moment from 'moment';
import 'moment/locale/fr'
import UserContext from "../../utils/UserContext";
import '../../assets/stylesheets/global.sass'

moment.locale('fr');

AntForm.configure({
  formProps: {
    layout: 'vertical',
  },
});

AntForm.addField('nested', {
  component: AntFormRailsNested,
  showFormItemError: false,
});

type IProps = {
  user: any;
}

const { client } = graphqlClient({ url: '/graphql' });

const App: React.FC<IProps> = props => {
  return (
    <ConfigProvider locale={fr}>
      <UserContext.Provider value={props.user}>
        <BrowserRouter history={history}>
          <ApolloProvider client={client}>
            <Layout user={props.user}>
              <Switch>
                {flattenRoutes(routes).map(route => (
                  <Route key={route.name} exact path={route.path} component={route.component} />
                ))}
              </Switch>
            </Layout>
          </ApolloProvider>
        </BrowserRouter>
      </UserContext.Provider>
    </ConfigProvider>
  )
}

export default TranslationProvider(App);
