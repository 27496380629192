import logo from './assets/images/logo.svg';

const config = {
  authentication: {
    logo,
    providers: {
      email: {
        enable: true,
      }
    },
  }
};

export default config;
